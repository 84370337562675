import { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'

export default function SectionTwo() {
  const [activeIndex, setActiveIndex] = useState(0)

  const items = [
    {
      src: '../images/lp/clube-do-tennis-05.png',

      altText: 'PERFIL DO TENISTA',
      caption:
        'Todas informações sobre seus Desafios, Estatísticas, Conquistas e Conexões',
    },
    {
      src: '../images/lp/clube-do-tennis-03.png',
      altText: 'RANKING CDT',
      caption:
        'Jogue com tenistas do seu nível e dispute pontos em cada partida',
    },
    {
      src: '../images/lp/clube-do-tennis-04.png',
      altText: 'CONQUISTAS',
      caption:
        'A cada objetivo concluído, uma nova Conquista para colecionar e exibir no Card',
    },
    {
      src: '../images/lp/clube-do-tennis-02.png',

      altText: 'PROFESSORES',
      caption: 'Encontre professores ou academias próximas e aprimore seu jogo',
    },
  ]

  return (
    <div
      style={{
        backgroundImage: "url('../images/lp/clube-do-tennis-06.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '3rem',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '50%',
          background:
            'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
          zIndex: 0,
        }}
      />
      <Container className="section-two">
        <Row>
          <Col className="text-area">
            <h3>Vantagens de ser membro</h3>
            <p className="fs-3 fw-light">
              O Clube do Tennis é a plataforma completa para quem quer conhecer
              novos <br />
              tenistas, desafiar-se em partidas pelo Ranking e evoluir no
              esporte
            </p>
          </Col>
        </Row>

        {/* Carousel para mobile */}
        <Row className="mt-4 d-md-none">
          <Col>
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="false"
            >
              <div className="carousel-inner">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
                  >
                    <img
                      src={item.src}
                      alt={item.altText}
                      className="d-block w-100 img-fluid"
                    />
                    <div className="carousel-text mt-3">
                      <h4>{item.altText}</h4>
                      <p>{item.caption}</p>
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev font-weight-bold"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
                onClick={() =>
                  setActiveIndex(
                    activeIndex === 0 ? items.length - 1 : activeIndex - 1,
                  )
                }
                style={{
                  position: 'absolute',
                  left: '-15%',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
                onClick={() =>
                  setActiveIndex(
                    activeIndex === items.length - 1 ? 0 : activeIndex + 1,
                  )
                }
                style={{
                  position: 'absolute',
                  right: '-15%',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </Col>
        </Row>

        {/* Grid para Desktop */}
        <Row className="mt-4 d-none d-md-flex slider-container">
          {items.map((item, index) => (
            <Col md="3" key={index} className="slide">
              <img src={item.src} alt={item.altText} className="img-fluid" />
              <h4>{item.altText}</h4>
              <p>{item.caption}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
