import { Link } from '@remix-run/react'
import { Container, Col } from 'reactstrap'

export default function SectionFour() {
  return (
    <div className="section-four">
      <Container className="section-four-container">
        <Col className="text-area" md="8" xs="10">
          <h3>CRIE SUA CONTA AGORA</h3>
          <div className="border-0">
            <Link
              to="/cadastre-se"
              className="d-inline-flex justify-content-center align-items-center btn btn-lg text-white w-100 border border-1"
            >
              Criar conta{' '}
              <i className="bx bx-chevron-right" style={{ fontSize: 20 }} />
            </Link>
          </div>
        </Col>
      </Container>
    </div>
  )
}
