import { Container, Row, Col } from 'reactstrap'
import NavBar from './NavBar.tsx'

export default function Hero() {
  return (
    <div
      style={{
        height: '100vh',
        position: 'relative',
        color: 'white',
        zIndex: 0,
      }}
    >
      <div
        style={{
          backgroundImage: "url('../images/lp/bg-hero-section.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.9)',
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(to bottom, rgba(66, 59, 170, 0.4), rgba(66, 59, 170, 0.2))',
            zIndex: -1,
          }}
        />
        <NavBar />
        <Container fluid className="hero">
          <Row className="justify-content-start h-100 w-100">
            <Col
              md="8"
              className="d-flex flex-column justify-content-center text-left"
            >
              <h1 className="hero-text">
                O NOVO JEITO
                <br />
                DE JOGAR
                <br />
                TENNIS.
              </h1>
            </Col>
          </Row>
          <Row>
            <Col
              md="12"
              className="d-flex justify-content-center align-items-end"
            >
              <img
                src="../images/lp/scroll-down-animation.gif"
                alt="scroll-down"
                className="animation"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
