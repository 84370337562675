import { type SetStateAction, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from 'reactstrap'

export default function SectionFive() {
  const faqs = [
    {
      id: 1,
      question: 'Preciso pagar para criar minha conta?',
      answer:
        'Não. O Clube do Tennis é uma plataforma gratuita com o objetivo de conectar a maior quantidade possível de tenistas.',
    },
    {
      id: 2,
      question: 'Como descubro qual o meu nível?',
      answer:
        'Na etapa final de criação de conta, o Clube do Tennis te mostrará os 7 níveis disponíveis com a explicação de cada um, para que você avalie qual se encaixa melhor no seu momento de jogo.',
    },
    {
      id: 3,
      question: 'Posso cancelar um Desafio agendado?',
      answer:
        'Sim. Para Desafios válidos pelo Ranking CdT, o cancelamento em até 2 horas antes da partida não implica em perda do jogo, logo, em perda de pontos. Cancelamentos com menos de 2 horas de antecedência darão ao outro jogador a possibilidade de cancelar o Desafio ou aplicar um W.O., com distribuição de pontos do Desafio.',
    },
    {
      id: 4,
      question: 'O Clube do Tennis faz a locação da quadra?',
      answer:
        'Não. Os tenistas que forem participar do Desafio devem entrar em acordo para seleção do local do Desafio e fazer a locação da quadra, caso necessário.',
    },
    {
      id: 5,
      question: 'Como faço para buscar desafios?',
      answer:
        'No Dashboard da plataforma você encontrará uma seleção de Desafios recomendados, filtrados pelo seu nível e proximidade. Ao clicar no botão "ver todos", você entrará na página de busca com diversos filtros para aplicar e chegar nos melhores Desafios para você.',
    },
    {
      id: 6,
      question: 'Tenho vantagens ao indicar para amigos?',
      answer:
        'Sim. No seu perfil, você encontrará um link de indicação. Caso seus amigos criem a conta por lá, você receberá recompensas, que podem ser descontos na Loja CdT, vale compras e Conquistas.',
    },
  ]

  const [open, setOpen] = useState('')

  const toggle = (id: SetStateAction<string>) => {
    setOpen(open === id ? '' : id)
  }

  return (
    <div className="section-five">
      <Container className="section-five-container fluid">
        <Row>
          <Col className="text-area" xs="12">
            <h3>DÚVIDAS FREQUENTES SOBRE O CLUBE DO TENNIS</h3>
            <Accordion flush open={open} toggle={toggle}>
              <Row>
                <Col md="6" xs="12">
                  {faqs.slice(0, 3).map((q, i) => (
                    <AccordionItem
                      key={i}
                      className="my-2"
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                      }}
                    >
                      <AccordionHeader targetId={String(q.id)}>
                        {q.question}
                      </AccordionHeader>
                      <AccordionBody accordionId={String(q.id)}>
                        {q.answer}
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </Col>
                <Col md="6" xs="12">
                  {faqs.slice(3, 6).map((q, i) => (
                    <AccordionItem
                      key={i}
                      className="my-2"
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                      }}
                    >
                      <AccordionHeader targetId={String(q.id)}>
                        {q.question}
                      </AccordionHeader>
                      <AccordionBody accordionId={String(q.id)}>
                        {q.answer}
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </Col>
              </Row>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
