import { Link } from '@remix-run/react'
import { Container, Row, Col, NavItem } from 'reactstrap'

export default function Footer() {
  const links = [
    { title: 'Quem somos', url: '/como-funciona' },
    { title: 'Criar conta', url: '/cadastre-se' },
    { title: 'Termo de uso', url: '/clube/termos-de-uso' },
    { title: 'Loja Cdt', url: 'https://loja.clubedotennis.com.br' },
    { title: 'Contato', url: '/contato' },
    { title: '(11) 93903-5718', url: 'https://wa.me/+5511939035718' },
    {
      title: 'contato@clubedotennis.com.br',
      url: 'mailto:contato@clubedotennis.com.br',
    },
  ]

  return (
    <footer className="footer-lp">
      <Container>
        <Row className="justify-content-center align-items-start">
          <Col md="3" className="text-center">
            <img
              src="../images/logo-white.svg"
              alt="logo clube do tennis"
              className="img-fluid"
            />
            <h3 style={{ visibility: 'hidden' }}>Clube do Tennis</h3>
          </Col>

          <Col md="2" xs="12" className="d-flex flex-column">
            <Row>
              {/* renderiza em mobile */}
              <Col md="12" xs="6" className="d-block d-md-none">
                {links.slice(0, 2).map(link => (
                  <>
                    {link && (
                      <NavItem
                        key={link.title}
                        style={{
                          listStyleType: 'none',
                        }}
                      >
                        <Link
                          to={link.url}
                          className="nav-link"
                          style={{
                            fontSize: '1rem',
                            lineHeight: 3,
                            fontWeight: 600,
                          }}
                        >
                          {link.title}
                        </Link>
                      </NavItem>
                    )}
                  </>
                ))}
              </Col>

              {/* renderiza somente em desktop */}
              <Col md="12" xs="6" className="d-none d-md-block">
                {links.slice(0, 3).map(link => (
                  <>
                    {link && (
                      <NavItem
                        key={link.title}
                        style={{
                          listStyleType: 'none',
                        }}
                      >
                        <Link
                          to={link.url}
                          className="nav-link"
                          style={{
                            fontSize: '1rem',
                            lineHeight: 3,
                            fontWeight: 600,
                          }}
                        >
                          {link.title}
                        </Link>
                      </NavItem>
                    )}
                  </>
                ))}
              </Col>

              <Col md="12" xs="6" className="d-block d-md-none">
                {links.slice(2, 4).map(link => (
                  <>
                    {link && (
                      <NavItem
                        key={link.title}
                        style={{
                          listStyleType: 'none',
                        }}
                      >
                        <Link
                          to={link.url}
                          className="nav-link"
                          style={{
                            fontSize: '1rem',
                            lineHeight: 3,
                            fontWeight: 600,
                          }}
                        >
                          {link.title}
                        </Link>
                      </NavItem>
                    )}
                  </>
                ))}
              </Col>
            </Row>
          </Col>

          <Col md="2" className="d-none d-md-flex flex-column ">
            {links.slice(3, 4).map(link => (
              <>
                {link && (
                  <NavItem
                    key={link.title}
                    style={{
                      listStyleType: 'none',
                    }}
                  >
                    <Link
                      to={link.url}
                      className="nav-link"
                      style={{
                        fontSize: '1rem',
                        lineHeight: 3,
                        fontWeight: 600,
                      }}
                    >
                      {link.title}
                    </Link>
                  </NavItem>
                )}
              </>
            ))}
          </Col>

          <Col md="2" className="d-flex flex-column d">
            {links.slice(4, 7).map(link => (
              <>
                {link && (
                  <NavItem
                    key={link.title}
                    style={{
                      listStyleType: 'none',
                      width: '180px',
                    }}
                  >
                    <Link
                      to={link.url}
                      className="nav-link"
                      style={{
                        fontSize: '1rem',
                        lineHeight: 3,
                        fontWeight:
                          link.title.includes('1') || link.title.includes('@')
                            ? 400
                            : 600,
                      }}
                    >
                      {link.title}
                    </Link>
                  </NavItem>
                )}
              </>
            ))}
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
